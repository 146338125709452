<template>
  <div class="plans text-center py-5">
    <div class="row">
      <div class="col-12">
        <h2 class="font-weight-bold">Conoce nuestros planes</h2>
      </div>
      <div class="col-12">
        <p class="lead">Elige el Plan que mejor se adapte a tus necesidades</p>
      </div>
      <div class="col-12">
        <Carousel v-bind:autoplay="true"
          v-bind:perPageCustom="[[0, 1], [720, 2], [1024, 3], [1200, 4]]"
          v-bind:scrollPerPage="true">
          <Slide class="col-12 mt-4 pt-2" v-for="plan in plans" v-bind:key="plan.id">
            <div class="card pricing-rates business-rate shadow bg-white border-0 rounded">
              <div class="ribbon ribbon-right ribbon-warning overflow-hidden" v-if="plan.featured">
                <span class="text-center d-block shadow small h6">Best</span>
              </div>
              <div class="card-body">
                <h6 class="title font-weight-bold text-uppercase text-primary mb-4">
                  {{plan.name}}
                </h6>
                <div class="d-flex mb-4">
                  <span class="h4 mb-0 mt-2">$</span>
                  <span class="price h1 mb-0">{{plan.price}}</span>
                  <span class="h4 align-self-end mb-1">/{{getBillingFrequencyAbbr(plan.billingFrequency)}}</span>
                </div>
                <ul class="list-unstyled mb-0 pl-0">
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>Feature 1
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>Feature 2
                  </li>
                  <li class="h6 text-muted mb-0">
                    <span class="text-primary h5 mr-2"><i class="uil uil-check-circle align-middle"></i></span>Feature 3
                  </li>
                </ul>
                <router-link class="btn btn-primary mt-4"
                  v-bind:to="{ name: 'Checkout', params: { id: plan.id, }, }">
                  Get Started
                </router-link>
              </div>
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, } from "vuex";
  import { Carousel, Slide, } from "vue-carousel";
  export default {
    components: {
      Carousel,
      Slide,
    },
    computed: {
      ...mapGetters({
        plans: "plans/ordered",
      }),
    },
    methods: {
      getBillingFrequencyAbbr(frequency) {
        switch (frequency) {
          case "daily"  : return "day";
          case "weekly" : return "week";
          case "monthly": return "month";
          case "yearly" : return "year";
        }
      }
    }
  };
</script>

<style lang="scss">
  .VueCarousel-wrapper {
    padding-bottom: 1rem;
  }
</style>